<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-fluid">
                    <h4>
                        <strong>보고된 사용자 세부정보</strong>
                    </h4>
                    <div class="p-field p-grid">
                        <label for="category_name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">채팅방 이름 :</label>
                        <div class="p-col-12 p-md-10">
                            <p>{{ reportedUserDetails.room_name }}</p>
                        </div>
                    </div>

                    <div class="p-field p-grid">
                        <label for="material_name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">신고 사유:</label>
                        <div class="p-col-12 p-md-10">
                            <p>{{ reportedUserDetails.report_reason }}</p>
                        </div>
                    </div>

                    <div class="p-field p-grid">
                        <label for="material_name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">피신고자:</label>
                        <div class="p-col-12 p-md-10">
                            <p>{{ reportedUserDetails.report_user }}</p>
                        </div>
                    </div>

                    <div class="p-field p-grid">
                        <label for="material_name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">신고자 :</label>
                        <div class="p-col-12 p-md-10">
                            <p>{{ reportedUserDetails.report_by }}</p>
                        </div>
                    </div>

                    <div class="p-field p-grid">
                        <label for="material_name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">채팅방 유형 :</label>
                        <div class="p-col-12 p-md-10">
                            <!-- <p>{{ reportedUserDetails.room_type }}</p> -->
                            <p v-if="reportedUserDetails.room_type === 'general'">일반</p>
                            <p v-if="reportedUserDetails.room_type === 'location'">위치 기반</p>
                            <p v-if="reportedUserDetails.room_type === 'package'">패키지</p>
                        </div>
                    </div>

                    <div class="p-field p-grid">
                        <label for="material_name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">상세 내용:</label>
                        <div class="p-col-12 p-md-10">
                            <p>{{ reportedUserDetails.report_detail }}</p>
                        </div>
                    </div>

                    <div class="p-field p-grid">
                        <label for="material_name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">신고 일시 :</label>
                        <div class="p-col-12 p-md-10">
                            <p>{{ Dateformat(reportedUserDetails.reportDate) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
  import { onMounted, ref } from 'vue';
  import ReportedUsersService from '../../service/API/ReportedUsersService';
  import { useRoute } from 'vue-router';
  import moment from 'moment'
  const route = useRoute();
  const paramId = ref(null);
  paramId.value = route.params.id;
  const reportedUserDetails = ref({});
  const reportedUsersService = new ReportedUsersService();
  const getDetails = async () => {
      try {
          let res = await reportedUsersService.getReportedUsersDetails(paramId.value);
          if (res.status == 200) {
              reportedUserDetails.value = res.data.message_details;
          }
      } catch (err) {
          console.log(err);
      }
  };
  onMounted(() => {
      getDetails();
  });
  const Dateformat = (value) => {
      if (value) {
          return moment(String(value)).locale('ko').format('ll - LTS');
      }
  };
</script>